import React, { useState, useMemo, useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Alert, Col, Form, Row } from "react-bootstrap";
import {
  BooleanParam,
  DelimitedArrayParam,
  JsonParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  Positions,
  PlayerTypes,
  Conferences,
  MostRecentLeagues,
} from "../constants/AppConstants";
import { UserContext } from "../UserContext";
import { BoardMakerPlayer } from "../../shared/routers/BoardRouter";
import { DynamicFilterGroup } from "../components/query/DynamicFilterGroup";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { Restrict } from "../components/core/Restrict";
import { groupBy } from "../../shared/util/Collections";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import { PlayerRatingScaleToolTip } from "../components/player/PlayerRatingScaleTooltip";
import {
  decFormat,
  decFormat2,
  abbreviatedMoneyFormat,
  ordinalFormat,
  pctFormat,
} from "../util/Format";
import { trpc } from "../util/tRPC";
import { PlayerTableCell } from "../components/core/TableCell";
import { sortNullsToEnd } from "../util/Sort";

interface BoardMakerFilter {
  value: keyof BoardMakerPlayer;
  label: string;
  biaOnly?: boolean;
  orderBy?: boolean;
  type?: "boolean" | "multiselect" | "number";
  options?: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      display: "flex",
      overflowX: "scroll",
      marginTop: -15,
      paddingTop: 15,
    },
    table: {
      marginLeft: 15,
      marginRight: 15,
    },
  })
);

const ALL_FILTERS: BoardMakerFilter[] = [
  {
    value: "PlayerType",
    label: "Player Type",
    orderBy: true,
    type: "multiselect",
    options: PlayerTypes,
  },
  { value: "Age", label: "Age", orderBy: true, type: "number" },
  {
    value: "Conference",
    label: "Conference",
    orderBy: true,
    type: "multiselect",
    options: Conferences,
  },
  {
    value: "mostRecentLeague",
    label: "Most Recent League",
    orderBy: true,
    type: "multiselect",
    options: MostRecentLeagues,
  },
  {
    value: "playerLeagueType",
    label: "League Type",
    orderBy: true,
    type: "multiselect",
    options: [
      "HS",
      "Europe",
      "Rest of World",
      "G-League & U.S.",
      "College",
      "NBA",
    ],
  },
  {
    value: "nbaRights",
    label: "NBA Rights",
    orderBy: true,
    type: "multiselect",
    options: [
      "ATL",
      "BOS",
      "BRK",
      "CHA",
      "CHI",
      "CLE",
      "DAL",
      "DEN",
      "DET",
      "GSW",
      "HOU",
      "IND",
      "LAC",
      "LAL",
      "MEM",
      "MIA",
      "MIL",
      "MIN",
      "NOP",
      "NYK",
      "OKC",
      "ORL",
      "PHI",
      "PHX",
      "POR",
      "SAC",
      "SAS",
      "TOR",
      "UTA",
      "WAS",
    ],
  },
  {
    value: "playedLast6Mos",
    label: "Played Last 6 Months",
    type: "boolean",
  },
  { value: "pNBA", label: "pNBA", orderBy: true, type: "number" },
  { value: "currentSalary", label: "Salary", orderBy: true, type: "number" },
  {
    value: "estimatedSalary",
    label: "Est. Salary",
    orderBy: true,
    type: "number",
  },
  {
    value: "estimatedSalaryImpact",
    label: "Est. Salary (Impact)",
    orderBy: true,
    type: "number",
  },
  { value: "netImpact", label: "Net Impact", orderBy: true, type: "number" },
  { value: "offImpact", label: "Off. Impact", orderBy: true, type: "number" },
  { value: "defImpact", label: "Def. Impact", orderBy: true, type: "number" },
  { value: "GLeague", label: "Is in G-League", type: "boolean" },
  { value: "upcomingFa", label: "Upcoming Free Agent", type: "boolean" },
  {
    value: "contractType",
    label: "Contract Type",
    orderBy: true,
    type: "multiselect",
    options: [
      "RFA",
      "UFA",
      "PO",
      "TO",
      "ETO",
      "0% GTD",
      "2% GTD",
      "5% GTD",
      "50% GTD",
      "9% GTD",
    ],
  },
  { value: "autoEligible", label: "Draft Auto-Eligible", type: "boolean" },
  { value: "twoWayEligible", label: "Two Way Eligible", type: "boolean" },
  {
    value: "peakImpactPred",
    label: "Peak Impact Pred.",
    orderBy: true,
    type: "number",
  },
  {
    value: "peakOffImpactPred",
    label: "Peak Off. Impact Pred.",
    orderBy: true,
    type: "number",
  },
  {
    value: "peakDefImpactPred",
    label: "Peak Def. Impact Pred.",
    orderBy: true,
    type: "number",
  },
  {
    value: "estDaysMissedPerSeason",
    label: "Est. Days Missed Per Season",
    orderBy: true,
    type: "number",
  },
  {
    value: "projectedAvailability",
    label: "Projected Availability",
    orderBy: true,
    type: "number",
  },
  { value: "APM", label: "APM", biaOnly: true, orderBy: true, type: "number" },
  {
    value: "OAPM",
    label: "OAPM",
    biaOnly: true,
    orderBy: true,
    type: "number",
  },
  {
    value: "DAPM",
    label: "DAPM",
    biaOnly: true,
    orderBy: true,
    type: "number",
  },
  {
    value: "Ceiling",
    label: "Drew's Ceiling",
    biaOnly: true,
    orderBy: true,
    type: "number",
  },
  {
    value: "Likely",
    label: "Drew's Likely",
    biaOnly: true,
    orderBy: true,
    type: "number",
  },
  {
    value: "Scout",
    label: "BOS Scouts",
    orderBy: true,
    type: "number",
  },
  // {
  //   value: "useCameraStats",
  //   label: "Uses Camera Stats",
  //   biaOnly: true,
  //   orderBy: true,
  //   type: "boolean",
  // },
  {
    value: "hasAllData",
    label: "Has Wingspan, Speed, Synergy",
    biaOnly: true,
    orderBy: true,
    type: "boolean",
  },
  {
    value: "hasDrewCeilingAndLikely",
    label: "Has Drew's Ceiling and Likely",
    biaOnly: true,
    orderBy: true,
    type: "boolean",
  },
  {
    value: "dxRank",
    label: "DX Rank",
    orderBy: true,
    type: "number",
  },
  {
    value: "netImpactIdeal",
    label: "Net Impact (Ideal)",
    orderBy: true,
    type: "number",
  },
  {
    value: "offImpactIdeal",
    label: "Off. Impact (Ideal)",
    orderBy: true,
    type: "number",
  },
  {
    value: "defImpactIdeal",
    label: "Def. Impact (Ideal)",
    orderBy: true,
    type: "number",
  },
  {
    value: "netImpactIdealGap",
    label: "Net Impact Ideal Gap",
    orderBy: true,
    type: "number",
  },

  {
    value: "meanReg3PAPer100",
    label: "3PA/100",
    orderBy: true,
    type: "number",
  },
];

// Filterable columns that we don't want to show in the table UI.
const NON_COLUMN_FILTERS = ["hasAllData", "hasDrewCeilingAndLikely"];

const DEFAULT_SELECTED_COLUMNS: (keyof BoardMakerPlayer)[] = [
  "netImpact",
  "offImpact",
  "defImpact",
];

export function BoardMakerPage() {
  const user = useContext(UserContext);
  const [queryParams, setQueryParams] = useQueryParams({
    orderBy: withDefault(StringParam, "netImpact"),
    desc: withDefault(BooleanParam, true),
    selectedColumns: withDefault(
      DelimitedArrayParam,
      DEFAULT_SELECTED_COLUMNS
    ) as QueryParamConfig<string[]>,
    filters: withDefault(JsonParam, [
      { key: "PlayerType", value: ["Trade"] },
    ]) as QueryParamConfig<{ key: string; value: unknown }[]>,
    split: withDefault(StringParam, "position"),
  });
  const { orderBy, desc, filters, split } = queryParams;
  const selectedColumns = new Set(queryParams.selectedColumns);

  const allFilters = ALL_FILTERS.filter(
    (af) => !af.biaOnly || (user && user.group === "bia")
  );

  const { data: boards } = trpc.board.getBoardMakerPlayers.useQuery({
    orderBy,
    direction: desc ? "desc" : "asc",
    filters: JSON.stringify(filters),
  });

  const onSelectColumnChange = (col: { value: string; label: string }) => {
    const checked = selectedColumns.has(col.value);
    if (checked) {
      selectedColumns.delete(col.value);
    } else {
      selectedColumns.add(col.value);
    }
    setQueryParams({ selectedColumns: [...selectedColumns.values()] });
  };

  return (
    <Page header={{ text: "Board Maker" }} title={"Board Maker"}>
      <div>
        <Row>
          <Col>
            <Panel header={"Filters"}>
              <div>
                <DynamicFilterGroup
                  allFilters={allFilters.map((f) => {
                    return {
                      ...f,
                      group: !f.biaOnly ? "Popular" : "Bia Only",
                    };
                  })}
                  filters={filters}
                  setFilters={(val) => setQueryParams({ filters: val })}
                />
                <Row style={{ marginTop: 12 }}>
                  <Col>
                    <Form.Label>Order By</Form.Label>
                    <div>
                      <Form.Select
                        style={{
                          display: "inline-block",
                          width: "auto",
                          marginRight: 10,
                        }}
                        value={orderBy}
                        onChange={(e) =>
                          setQueryParams({ orderBy: e.target.value })
                        }
                      >
                        <optgroup label="Popular">
                          {allFilters
                            .filter((f) => f.orderBy && !f.biaOnly)
                            .map((f) => {
                              return (
                                <option key={f.value} value={f.value}>
                                  {f.label}
                                </option>
                              );
                            })}
                        </optgroup>
                        <Restrict roles={["bia"]}>
                          <optgroup label="BIA Only">
                            {allFilters
                              .filter((f) => f.orderBy && f.biaOnly)
                              .map((f) => {
                                return (
                                  <option key={f.value} value={f.value}>
                                    {f.label}
                                  </option>
                                );
                              })}
                          </optgroup>
                        </Restrict>
                      </Form.Select>
                      <Form.Select
                        value={desc ? "desc" : "asc"}
                        style={{
                          display: "inline-block",
                          width: "auto",
                          marginRight: 10,
                        }}
                        onChange={(e) =>
                          setQueryParams({ desc: e.target.value === "desc" })
                        }
                      >
                        <option value={"asc"}>Asc</option>
                        <option value={"desc"}>Desc</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }}>
                  <Col>
                    <Form>
                      <div>
                        <Form.Label>Select Columns</Form.Label>
                      </div>
                      {allFilters
                        .filter((f) => !NON_COLUMN_FILTERS.includes(f.value))
                        .map((f) => {
                          return (
                            <Form.Check
                              type="checkbox"
                              key={f.value}
                              inline
                              onChange={() => onSelectColumnChange(f)}
                              checked={selectedColumns.has(f.value)}
                              label={f.label}
                            />
                          );
                        })}
                    </Form>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel header={"Results"}>
              {boards ? (
                <BoardMakerResultsPanel
                  boards={boards}
                  selectedColumns={selectedColumns}
                  split={split}
                  setSplit={(val) => setQueryParams({ split: val })}
                />
              ) : (
                <Spinner />
              )}
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

function BoardMakerResultsPanel(props: {
  boards: BoardMakerPlayer[];
  selectedColumns: Set<string>;
  split: string;
  setSplit: (val: string) => void;
}) {
  const classes = useStyles();
  const { boards, selectedColumns, split, setSplit } = props;
  const groupByPos = groupBy(boards, (b: BoardMakerPlayer) =>
    b.position.toString()
  );
  const groupByType = groupBy(boards, (b: BoardMakerPlayer) =>
    positionToPlayerType(b.position)
  );

  if (boards.length === 0) {
    return <Alert variant="warning">No players match your filters.</Alert>;
  }

  return (
    <div>
      <Form.Label>Split By</Form.Label>
      <Form.Select
        value={split}
        onChange={(e) => setSplit(e.target.value)}
        style={{
          marginLeft: 8,
          marginBottom: 10,
          width: "auto",
          display: "inline-block",
        }}
      >
        <option value="position">Position</option>
        <option value="type">Guard/Wing/Big</option>
        <option value="none">No Split</option>
      </Form.Select>
      {split === "none" && (
        <BoardMakerTable boards={boards} selectedColumns={selectedColumns} />
      )}
      {split === "position" && (
        <div className={classes.tableContainer}>
          {Object.entries(Positions).map(([key, val]) => {
            return (
              <div key={key} className={classes.table}>
                {groupByPos[key] && (
                  <div>
                    <div>{val}s</div>
                    <BoardMakerTable
                      boards={groupByPos[key] || []}
                      selectedColumns={selectedColumns}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {split === "type" && (
        <div className={classes.tableContainer}>
          {["Guard", "Wing", "Big"].map((val) => {
            return (
              <div key={val} className={classes.table}>
                {groupByType[val] && (
                  <div>
                    <div>{val}s</div>
                    <BoardMakerTable
                      boards={groupByType[val] || []}
                      selectedColumns={selectedColumns}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

const boardMakerColumnHelper = createColumnHelper<BoardMakerPlayer>();

function BoardMakerTable(props: {
  boards: BoardMakerPlayer[];
  selectedColumns: Set<string>;
}) {
  const { boards, selectedColumns } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;

    return [
      boardMakerColumnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
          />
        ),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("PlayerType", {
        header: () => "Player Type",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      boardMakerColumnHelper.accessor("Age", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("Conference", {
        header: () => "Conference",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      boardMakerColumnHelper.accessor("mostRecentLeague", {
        header: () => "Most Recent League",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      boardMakerColumnHelper.accessor("playerLeagueType", {
        header: () => "League Type",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      boardMakerColumnHelper.accessor("nbaRights", {
        header: () => "NBA Rights",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      boardMakerColumnHelper.accessor("playedLast6Mos", {
        header: () => "Played Last 6 Months",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("pNBA", {
        header: () => "pNBA",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("currentSalary", {
        header: () => "Salary",
        cell: (info) => abbreviatedMoneyFormat(info.getValue()),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("estimatedSalary", {
        header: () => "Est. Salary",
        cell: (info) => abbreviatedMoneyFormat(info.getValue()),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("estimatedSalaryImpact", {
        header: () => "Est. Salary (Impact)",
        cell: (info) => abbreviatedMoneyFormat(info.getValue()),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("netImpact", {
        header: () => "Net Impact",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "netImpact"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("offImpact", {
        header: () => "Off. Impact",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "offImpact"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("defImpact", {
        header: () => "Def. Impact",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "defImpact"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("GLeague", {
        header: () => "Is In G-League",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("upcomingFa", {
        header: () => "Upcoming Free Agent",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("contractType", {
        header: () => "Contract Type",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      boardMakerColumnHelper.accessor("autoEligible", {
        header: () => "Draft Auto-Eligible",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("twoWayEligible", {
        header: () => "Two Way Eligible",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("peakImpactPred", {
        header: () => "Peak Impact Pred.",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "peakImpactPred"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("peakOffImpactPred", {
        header: () => "Peak Off. Impact Pred.",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "peakOffImpactPred"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("peakDefImpactPred", {
        header: () => "Peak Def. Impact Pred.",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "peakDefImpactPred"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("estDaysMissedPerSeason", {
        header: () => "Est. Days Missed Per Season",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("projectedAvailability", {
        header: () => "Proj. Availability",
        cell: (info) => pctFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "projectedAvailability"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("APM", {
        header: () => "APM",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) => sortNullsToEnd(a.original, b.original, "APM"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("OAPM", {
        header: () => "OAPM",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) => sortNullsToEnd(a.original, b.original, "OAPM"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("DAPM", {
        header: () => "DAPM",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) => sortNullsToEnd(a.original, b.original, "DAPM"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("Ceiling", {
        header: () => (
          <PlayerRatingScaleToolTip>
            {"Drew's Ceiling"}
          </PlayerRatingScaleToolTip>
        ),
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) => sortNullsToEnd(a.original, b.original, "Ceiling"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("Likely", {
        header: () => (
          <PlayerRatingScaleToolTip>{"Drew's Likely"}</PlayerRatingScaleToolTip>
        ),
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) => sortNullsToEnd(a.original, b.original, "Likely"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("Scout", {
        header: () => "BOS Scouts",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) => sortNullsToEnd(a.original, b.original, "Scout"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("dxRank", {
        header: () => "DX Rank",
        cell: (info) => ordinalFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "dxRank", true),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("netImpactIdeal", {
        header: () => "Net Impact (Ideal)",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "netImpactIdeal"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("offImpactIdeal", {
        header: () => "Off. Impact (Ideal)",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "offImpactIdeal"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("defImpactIdeal", {
        header: () => "Def. Impact (Ideal)",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "defImpactIdeal"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("netImpactIdealGap", {
        header: () => "Net Impact Ideal Gap",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "netImpactIdealGap"),
        meta: { group: g++ },
      }),
      boardMakerColumnHelper.accessor("meanReg3PAPer100", {
        header: () => "3PA/100",
        cell: (info) => decFormat(info.getValue()),
        sortingFn: (a, b) =>
          sortNullsToEnd(a.original, b.original, "meanReg3PAPer100"),
        meta: { group: g++ },
      }),
    ];
  }, []);

  const visibility: Record<string, boolean> = {};
  for (const filter of ALL_FILTERS) {
    if (!selectedColumns.has(filter.value)) {
      visibility[filter.value] = false;
    }
  }

  return (
    <Table
      data={boards}
      columns={columns}
      hiddenColumns={visibility}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
    />
  );
}

function positionToPlayerType(pos: number) {
  if (pos <= 2) {
    return "Guard";
  } else if (pos <= 4) {
    return "Wing";
  }
  return "Big";
}
