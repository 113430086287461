import React, { useMemo, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import {
  SortingState,
  Table,
  createColumnHelper,
} from "../components/core/Table";
import { BiaUser } from "../../shared/routers/AdminRouter";
import { trpc } from "../util/tRPC";

const columnHelper = createColumnHelper<BiaUser>();

const groups = [
  "",
  "bia",
  "executive",
  "coach",
  "scout",
  "performance",
  "intern",
];

export function AdminPage() {
  const [sorting, setSorting] = useState<SortingState>();
  const utils = trpc.useContext();
  const userQuery = trpc.admin.getBiaUsers.useQuery();
  const updateBiaUserMutation = trpc.admin.updateBiaUser.useMutation({
    onSettled: () => {
      utils.admin.invalidate();
      utils.admin.getBiaUsers.refetch();
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: () => "Email",
      }),
      columnHelper.accessor("group", {
        header: () => "Group",
        cell: (info) => (
          <select
            value={info.getValue() || ""}
            onChange={(evt) =>
              updateBiaUserMutation.mutate({
                email: info.row.original.email,
                isAdmin: info.row.original.isAdmin,
                group: evt.target.value,
                isInsert: info.getValue() === null,
              })
            }
          >
            {groups.map((group) => (
              <option key={group}>{group}</option>
            ))}
          </select>
        ),
      }),
      columnHelper.accessor("isAdmin", {
        header: () => "Admin",
        cell: (info) => (
          <Form.Check
            type="checkbox"
            checked={info.getValue()}
            onChange={() =>
              updateBiaUserMutation.mutate({
                email: info.row.original.email,
                isAdmin: !info.getValue(),
                group: info.row.original.group || "",
                isInsert: info.row.original.group === null,
              })
            }
          />
        ),
      }),
      columnHelper.accessor("lastLogin", {
        header: () => "Last Login",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
      }),
    ],
    [updateBiaUserMutation]
  );

  const data = userQuery.data;

  return (
    <Page header={{ text: "Admin Page" }} title="Admin">
      <div>
        <Row>
          <Col xs={12}>
            <Panel header={"Users"}>
              <>
                {data ? (
                  <Table
                    data={data.sort((a, b) => (a.email > b.email ? 1 : -1))}
                    columns={columns}
                    showRowIndex={false}
                    autoWidth={true}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                ) : (
                  <Spinner />
                )}
              </>
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}
