import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Button } from "react-bootstrap";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      display: "block",
      width: "100%",
      height: 34,
      padding: "6px 12px",
      fontSize: 14,
      lineHeight: 1.42857,
      margin: 0,
      color: "black",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #ccc",
      borderRadius: 4,
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
    },
    formGroup: {
      marginBottom: 15,
    },
    formLabel: {
      display: "inline-block",
      maxWidth: "100%",
      marginBottom: 5,
      fontWeight: 700,
    },
    fullWidth: {
      width: "100%",
    },
    loginBox: {
      textAlign: "left",
      width: 300,
      margin: "24px auto",
      borderRadius: 10,
    },
    loginOuter: {
      textAlign: "center",
      margin: "120px 0",
    },
    mainLogo: {
      width: 300,
      background: "#ffffff",
      borderRadius: "50%",
      padding: 5,
      border: 0,
      verticalAlign: "middle",
    },
  })
);

const cognitoDomain = "ccba";
const region = "us-east-1";
const clientId = "5mg10hj951etbqdnshlhdggoa";
// URL where Cognito will redirect after login.
const domain =
  window.location.hostname === "localhost"
    ? "http://localhost:3001"
    : window.location.origin;
const redirectUri = `${domain}/api/callback`;
const loginUrl = `https://${cognitoDomain}.auth.${region}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

export function LoginPage() {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  return (
    <div className={classes.loginOuter}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      body {
        background-color: #363747;
        padding-top: 0px;
        color: white;
        font-size: 14px;
        line-height: 1.42857;
      }
      * { box-sizing: border-box; }

      /* For the spinning animation. */
      .spinner {
        margin-left: 8px; 
        width: 16px;
        animation: spin 1s linear infinite;
        fill: white;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }`,
        }}
      />
      <img src="/assets/img/BIA.png" className={classes.mainLogo}></img>
      <div className={classes.loginBox}>
        <Button
          type="button"
          variant="light"
          className={classes.fullWidth}
          onClick={() => {
            setClicked(true);
            window.location.replace(loginUrl);
          }}
        >
          Login
        </Button>
        {clicked && (
          <p
            style={{
              marginTop: 16,
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          >
            Authenticating with Microsoft
            <svg
              className="spinner"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M288 32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM448 256a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM32 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM75 437a32 32 0 1 0 45.3-45.3A32 32 0 1 0 75 437zm316.8 0A32 32 0 1 0 437 391.8 32 32 0 1 0 391.8 437zM75 75a32 32 0 1 0 45.3 45.3A32 32 0 1 0 75 75z"></path>
            </svg>
          </p>
        )}
      </div>
    </div>
  );
}
